import React from 'react';

import CustomerFeedback from '../Home/patterns/CustomerFeedback/CustomerFeedback';
import Hero from '../../components/Hero/Hero';
import FAQ from '../../components/FAQ/FAQ';
import ProductCard from '../../components/ProductCard/ProductCard';
import NuMessage from '../../components/NuMessage/NuMessage';
import ReasonsBelongingPurple from '../../components/ReasonsBelongingPurple/ReasonsBelongingPurple';
import NuDisclaimer from '../../components/NuDisclaimer/NuDisclaimer';
import ValueProposal from '../../components/ValueProposal/ValueProposal';
import { getEnableCucuta } from '../../utils/environmentUtils';

import ComponentDefaultProps from './variant/MultiProductHomeProps';
import HomePropType from './variant/HomePropTypes';
import Perks from './patterns/Perks/Perks';
import PressSection from './patterns/Press/Press';
import Blog from './patterns/Blog/Blog';

const MultiProductHomePage = () => {
  const defineProps = (getEnableCucuta === 'true') ? ComponentDefaultProps.cucuta : ComponentDefaultProps.multiproduct;

  const {
    hero,
    disclaimer,
    savingsProduct,
    savingsPerks,
    creditsProduct,
    creditsPerks,
    perks,
    reasons,
    press,
    blog,
    faq,
    feedbacks,
    nuMessage,
  } = defineProps;

  return (
    <>
      <Hero {...hero} />
      <NuDisclaimer {...disclaimer} />
      <ProductCard {...savingsProduct} />
      <ValueProposal {...savingsPerks} />
      <ProductCard {...creditsProduct} />
      <ValueProposal {...creditsPerks} />
      <Perks {...perks} />
      <ReasonsBelongingPurple {...reasons} />
      <CustomerFeedback feedbacks={feedbacks} />
      <PressSection {...press} />
      <NuMessage {...nuMessage} />
      <Blog {...blog} />
      <FAQ {...faq} />
    </>
  );
};

MultiProductHomePage.propTypes = HomePropType;

export default MultiProductHomePage;
