import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { css } from 'styled-components';
import PropTypes from 'prop-types';

const NuMessage = ({ title, spanColor }) => (
  <Box
    tag="section"
    padding={{ xs: '11rem 2rem 11rem 2rem', lg: '20rem 11rem 20rem 11rem' }}
  >
    <Typography
      intlKey={title}
      variant="heading2"
      tag="h2"
      textAlign={{ xs: 'center', md: 'center' }}
      css={css`span {color: ${spanColor};}`}
      intlValues={{
        span: msg => (
          <span>{msg}</span>
        ),
      }}
    />

  </Box>
);

NuMessage.propTypes = {
  spanColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NuMessage;
