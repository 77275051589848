import { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Image from '@nubank/nuds-web/components/Image/Image';
import Box from '@nubank/nuds-web/components/Box/Box';

import ImagePreload from '@nubank/www-latam-commons/components/ImagePreload/ImagePreload';
import StaticContent from '@nubank/www-latam-commons/components/StaticComponent/StaticComponent';
import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import MPSignUpForm from '../MPSignUpForm/MPSignUpForm';
import { INCREASE_CLARITY } from '../../utils/experiments';

import { StyledTypography } from './styles/Typography';
import { HeroContainer } from './styles/HeroContainer';
import { HeroPicture } from './styles/HeroPicture';
import { HeroForm } from './styles/HeroForm';
import { MPHeroSignUpForm } from './styles/MPHeroSignUpForm';

const Hero = ({
  title, subtitle, alt, heroImages, form, specificColor,
}) => {
  const activeVariant = useFigPiiExperiment(INCREASE_CLARITY.id);
  const isClarityExperimentOn = activeVariant === INCREASE_CLARITY.treatment;
  const formProps = { ...form, isClarityExperimentOn };

  const { formatMessage } = useIntl();
  const htmlTitleHeading = useMemo(() => formatMessage({ id: title }));
  const htmlSubtitleHeading = useMemo(() => formatMessage({ id: subtitle }));
  const descriptionImage = useMemo(() => formatMessage({ id: alt }));
  return (
    <HeroContainer>
      <ImagePreload srcSet={heroImages} />
      <HeroPicture>
        <Box>
          <Image
            srcSet={heroImages}
            loading="lazy"
            alt={descriptionImage}
            title={descriptionImage}
            webp
          />
        </Box>
      </HeroPicture>
      <HeroForm>
        <StaticContent>
          <Typography
            data-testid="hero-title"
            css={css`span {color: ${specificColor.startsWith('#') ? specificColor : nuDSColor('primary', specificColor)};}`}
            variant="heading1"
            gutterBottom={1}
            dangerouslySetInnerHTML={{ __html: htmlTitleHeading }}
          />
        </StaticContent>
        <>
          <StyledTypography
            data-testid="hero-subtitle"
            css={css`
              ${breakpointsMedia({
              xs: css`
                  font-size: 24px;
                  `,
              md: css`
                  font-size: 24px;
                `,
              lg: css`
                font-size: 40px;
                `,
            })}
            `}
            variant="heading2"
            gutterBottom={1}
            marginBottom={36}
            dangerouslySetInnerHTML={{ __html: htmlSubtitleHeading }}
          />
        </>
        <MPHeroSignUpForm color={isClarityExperimentOn ? '#000000' : specificColor}>
          <MPSignUpForm {...formProps} />
        </MPHeroSignUpForm>
      </HeroForm>
    </HeroContainer>
  );
};

Hero.defaultProps = {
  form: null,
  specificColor: 'default',
  tag: ' ',
  blurTag: null,
};

Hero.propTypes = {
  alt: PropTypes.string.isRequired,
  blurTag: PropTypes.string,
  form: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  heroImages: PropTypes.shape({
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
    xl: PropTypes.string,
    xs: PropTypes.string,
  }).isRequired,
  specificColor: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Hero;
