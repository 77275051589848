import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import maybeFormatMessage from '@nubank/nuds-web/utils/i18n/maybeFormatMessage';

import SEO from '@nubank/www-latam-commons/components/SEO/SEO';

import routes from '../../pages/routes.json';
import {
  domain, openGraph, baseBreadcrumb, corporateContactData,
} from '../../utils/seo';

const WebsiteSEO = ({ routeKey, openGraphResource }) => {
  const intl = useIntl();

  const {
    intlKey,
    noindex,
    nofollow,
    canonicals = {},
    alternate,
  } = routes[routeKey];

  const pageTitle = intl.formatMessage({ id: `${intlKey || routeKey}.HEAD.TITLE` });
  const pageDescription = intl.formatMessage({ id: `${intlKey || routeKey}.HEAD.META_DESCRIPTION` });
  const headBreadcrumb = maybeFormatMessage(intl, `${routeKey}.HEAD.BREADCRUMB_TITLE`);
  const canonicalPage = canonicals[intl.locale];

  return (
    <SEO
      pageTitle={pageTitle}
      description={pageDescription}
      noindex={noindex}
      nofollow={nofollow}
      canonicalPage={canonicalPage}
      alternatePages={alternate}
      breadcrumbTitle={headBreadcrumb}
      domain={domain}
      images={openGraphResource.images}
      baseBreadcrumb={baseBreadcrumb}
      siteName="Nu"
      corporateContactData={corporateContactData}
    />
  );
};

WebsiteSEO.defaultProps = {
  openGraphResource: openGraph.default,
};

WebsiteSEO.propTypes = {
  openGraphResource: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        height: PropTypes.number,
        url: PropTypes.string,
        width: PropTypes.number,
      }),
    ),
  }),
  routeKey: PropTypes.string.isRequired,
};

export default WebsiteSEO;
